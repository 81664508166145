import React from "react"
import { Link } from "gatsby"
import SEO from "../components/utils/seo"
import Layout from "../components/utils/layout"
import { useTranslation } from "react-i18next"

const Thanks = () => {
  const { t }= useTranslation()
  return (
    <Layout>
      <SEO title={t('thanks.seo-title')} />
      <h2>{t('thanks.title')} </h2>
      <p>{t('thanks.text')}</p>
      <br />
      <Link to="/#go">{t('go-back')}</Link>
    </Layout>
  )
}

export default Thanks
